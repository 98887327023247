<template>
	<div>
		<footer>
			<div class="row">
				<div class="columns column3 logo">
					<strong>Berlin</strong><br />
					<span>Hotel Template</span>
				</div>
				<div class="columns column3">
					<p>
						<strong>{{ defaults[locale].website.hotelName }}</strong
						><br />
						<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span
						><br />
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span
						><br />
						<span>{{ defaults[locale].website.country }}</span
						><br />
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<span>{{ defaults[locale].website.phone }}</span> </a
						><br />
						<a :href="`mailto:${defaults[locale].website.email}`"
							><span>{{ defaults[locale].website.email }}</span></a
						>
					</p>
					<social-media :socials="socials" />
				</div>
				<div class="columns column3">
					<p class="footer-nav">
						<strong>{{ $t('footerNavigate') }}</strong
						><br />
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }} <br />
						</nuxt-link>
					</p>
				</div>
				<div class="columns column3">
					<div v-parse-links v-html="defaults[locale].website.footerContent" />
				</div>
			</div>
		</footer>
		<footer class="author">
			<div class="row">
				<div class="columns column12">
					<div class="bottomfooter-row">
						<p class="align-left">
							<a
								href="https://www.porterforhotels.com/hotel-websites"
								target="_blank"
								rel="noopener noreferrer"
								title="Hotel website design by Porter"
							>
								Hotel Website Design
							</a>
							by:
							<a href="https://porterforhotels.com" target="_blank">
								<img class="siteByLogo" :src="$t('siteByLogo')" loading="lazy" alt="" />
								Porter</a
							>
						</p>
						<div class="menu align-right">
							<span v-for="(item, idx) in bottomfooter[locale]" :key="item.filename">
								<nuxt-link :to="item.filename">
									{{ item.header }}
								</nuxt-link>
								<span v-if="idx < bottomfooter[locale].length - 1">|</span>
							</span>
						</div>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu, bottomfooter } = footer.value;
const { locale } = useI18n();

const { defaults, socials, fetchDefaults } = useDefaults();

await fetchDefaults();
</script>

<style lang="scss" scoped>
footer {
	background: var(--footer-background-color);
	color: var(--body-color);
	padding: 100px 0 25px;
	font-size: 0.85rem;
}

a {
	color: var(--footer-link-color);
	text-decoration: none;

	&:hover {
		color: var(--cta-color);
	}
}

.logo {
	float: left;
	color: #000;
	font-size: 16px;

	strong {
		font-size: 30px;
		letter-spacing: 2px;
	}

	img {
		max-width: 100px;
	}
}

.author {
	background: var(--author-background-color);
	padding: 20px 0;
	font-size: 12px;

	p {
		margin: 0;
	}

	img {
		max-width: 20px;
		margin: 0 1px 0 2px;
	}
}

.bottomfooter-row {
	display: flex;
	align-items: center;

	.menu {
		margin-left: auto;
	}
}

@media (max-width: 900px) {
	footer .columns {
		margin: 20px auto;
		text-align: left;
	}

	footer.author .columns {
		text-align: center;
	}

	footer .row:last-child .columns:last-child a {
		margin: 0;
	}
}

@media (max-width: 600px) {
	footer {
		.socials {
			align-items: center;
			justify-content: center;
		}

		.columns {
			text-align: center;
		}
	}

	.author {
		padding: 20px 0 120px;
	}

	.bottomfooter-row {
		flex-flow: column-reverse wrap;
		align-items: center;

		.menu {
			margin: 0 auto 20px;
		}
	}
}
</style>
